import 'bootstrap/dist/css/bootstrap.css'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


import React from 'react';


//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';


//import { BrowserRouter, Routes, Route, } from "react-router-dom";
//import Widget from "./Widget";

import './index.css';

//import BuilderMain1 from './BuilderMainV6';

import App from './App';


import reportWebVitals from './reportWebVitals';







const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);



//ReactDOM.render(
  //<React.StrictMode>
    //<BuilderMain1 />
  //</React.StrictMode>,
  //document.getElementById('root')
//);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
